import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import ProtectedRoute from '../ProtectedRoute';
import UserProvider from '../../providers/UserProvider';
import MyLoadingOverlay from '../../components/MyLoadingOverlay';
import NotFound from '../../pages/NotFound';

const Login = lazy(() => import('../../pages/Login'));
const InscricoesPremiereBrasil = lazy(() =>
  import('../../pages/Inscricoes/PremiereBrasil'),
);
const InscricoesPremiereBrasilSucesso = lazy(() =>
  import('../../pages/Inscricoes/PremiereBrasilSucesso'),
);
const MovieImagePage = lazy(() => import('../../components/MovieImagePage'));
const Home = lazy(() => import('../../pages/Home'));
const PeliculaEdit = lazy(() => import('../../pages/Pelicula/Edit'));
const Catalogo = lazy(() => import('../../pages/Catalogo'));
const CatalogoCreate = lazy(() => import('../../pages/Catalogo/Create'));
const CatalogoDownload = lazy(() => import('../../pages/Catalogo/Download'));
const CatalogoRevista = lazy(() => import('../../pages/Catalogo/Revista'));
const CatalogoIngresso = lazy(() => import('../../pages/Catalogo/Ingresso'));
const PremiereSelecao = lazy(() => import('../../pages/Premiere/Selecao'));
const PremiereTodos = lazy(() => import('../../pages/Premiere/Todos'));
const PremiereMateriais = lazy(() => import('../../pages/Premiere/Materiais'));
const PremiereFinal = lazy(() => import('../../pages/Premiere/Final'));
const PremiereImprensa = lazy(() => import('../../pages/Premiere/Imprensa'));
const Projecao = lazy(() => import('../../pages/Projecao'));
const InternacionalTodos = lazy(() =>
  import('../../pages/Internacional/Todos'),
);
const InternacionalScreeners = lazy(() =>
  import('../../pages/Internacional/Screeners'),
);
const InternacionalMateriais = lazy(() =>
  import('../../pages/Internacional/Materiais'),
);
const InternacionalFinal = lazy(() =>
  import('../../pages/Internacional/Final'),
);
const InternacionalImprensa = lazy(() =>
  import('../../pages/Internacional/Imprensa'),
);
const ProgramacaoPelicula = lazy(() =>
  import('../../pages/Programacao/Pelicula'),
);
const ProgramacaoPeliculaEdit = lazy(() =>
  import('../../pages/Programacao/Pelicula/Edit'),
);
const ProgramacaoProgramacoes = lazy(() =>
  import('../../pages/Programacao/Programacoes'),
);
const ProgramacaoRenda = lazy(() => import('../../pages/Programacao/Renda'));
const Users = lazy(() => import('../../pages/Users'));
const UserCreate = lazy(() => import('../../pages/Users/Create'));
const UserEdit = lazy(() => import('../../pages/Users/Edit'));
const Mostras = lazy(() => import('../../pages/Mostras'));
const MostraCreate = lazy(() => import('../../pages/Mostras/Create'));
const MostraEdit = lazy(() => import('../../pages/Mostras/Edit'));
const SubMostras = lazy(() => import('../../pages/SubMostras'));
const SubMostraCreate = lazy(() => import('../../pages/SubMostras/Create'));
const SubMostraEdit = lazy(() => import('../../pages/SubMostras/Edit'));
const Cinemas = lazy(() => import('../../pages/Cinemas'));
const CinemaCreate = lazy(() => import('../../pages/Cinemas/Create'));
const CinemaEdit = lazy(() => import('../../pages/Cinemas/Edit'));
const Categorias = lazy(() => import('../../pages/Categorias'));
const CategoriaCreate = lazy(() => import('../../pages/Categorias/Create'));
const CategoriaEdit = lazy(() => import('../../pages/Categorias/Edit'));
const Vinhetas = lazy(() => import('../../pages/Vinhetas'));
const VinhetaCreate = lazy(() => import('../../pages/Vinhetas/Create'));
const VinhetaEdit = lazy(() => import('../../pages/Vinhetas/Edit'));
const Languages = lazy(() => import('../../pages/Idiomas'));
const LanguageCreate = lazy(() => import('../../pages/Idiomas/Create'));
const LanguageEdit = lazy(() => import('../../pages/Idiomas/Edit'));
const Directors = lazy(() => import('../../pages/Diretores'));
const DirectorCreate = lazy(() => import('../../pages/Diretores/Create'));
const DirectorEdit = lazy(() => import('../../pages/Diretores/Edit'));
const Elenco = lazy(() => import('../../pages/Elenco'));
const ElencoCreate = lazy(() => import('../../pages/Elenco/Create'));
const ElencoEdit = lazy(() => import('../../pages/Elenco/Edit'));
const Roteiristas = lazy(() => import('../../pages/Roteiristas'));
const RoteiristaCreate = lazy(() => import('../../pages/Roteiristas/Create'));
const RoteiristaEdit = lazy(() => import('../../pages/Roteiristas/Edit'));
const Distribuidoras = lazy(() => import('../../pages/Distribuidoras'));
const DistribuidoraCreate = lazy(() =>
  import('../../pages/Distribuidoras/Create'),
);
const DistribuidoraEdit = lazy(() => import('../../pages/Distribuidoras/Edit'));
const Produtoras = lazy(() => import('../../pages/Produtoras'));
const ProdutoraCreate = lazy(() => import('../../pages/Produtoras/Create'));
const ProdutoraEdit = lazy(() => import('../../pages/Produtoras/Edit'));
const Produtores = lazy(() => import('../../pages/Produtores'));
const ProdutorCreate = lazy(() => import('../../pages/Produtores/Create'));
const ProdutorEdit = lazy(() => import('../../pages/Produtores/Edit'));
const Fotografos = lazy(() => import('../../pages/Fotografos'));
const FotografoCreate = lazy(() => import('../../pages/Fotografos/Create'));
const FotografoEdit = lazy(() => import('../../pages/Fotografos/Edit'));
const Editores = lazy(() => import('../../pages/Editores'));
const EditorCreate = lazy(() => import('../../pages/Editores/Create'));
const EditorEdit = lazy(() => import('../../pages/Editores/Edit'));
const Musicos = lazy(() => import('../../pages/Musicos'));
const MusicoCreate = lazy(() => import('../../pages/Musicos/Create'));
const MusicoEdit = lazy(() => import('../../pages/Musicos/Edit'));
const DesenhistaDeSom = lazy(() => import('../../pages/DesenhistaDeSom'));
const DesenhistaDeSomCreate = lazy(() =>
  import('../../pages/DesenhistaDeSom/Create'),
);
const DesenhistaDeSomEdit = lazy(() =>
  import('../../pages/DesenhistaDeSom/Edit'),
);

function MainRoutes() {
  return (
    <Suspense fallback={<MyLoadingOverlay />}>
      <Router>
        <UserProvider>
          <Routes>
            <Route
              path="/inscricoes/premiere-brasil"
              exact
              element={<InscricoesPremiereBrasil />}
            />
            <Route
              path="/inscricoes/premiere-brasil/sucesso"
              exact
              element={<InscricoesPremiereBrasilSucesso />}
            />
            <Route path="*" element={<NotFound />} />
            <Route path="/" exact element={<Login />} />
            <Route path="/filme/:id" exact element={<MovieImagePage />} />
            <Route
              path="/dashboard"
              exact
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/lista-catalogo-premiere-brasil"
              exact
              element={
                <ProtectedRoute roles={[1, 2]}>
                  <Catalogo
                    filter="premiere"
                    submenu="list-catalogo-premiere-brasil"
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/lista-catalogo-filmes-internacionais"
              exact
              element={
                <ProtectedRoute roles={[1, 2]}>
                  <Catalogo
                    filter="internacionais"
                    submenu="list-catalogo-internacionais"
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/todos-filmes-catalogo-premiere-brasil"
              exact
              element={
                <ProtectedRoute roles={[1, 2]}>
                  <Catalogo
                    filter="premiere_all"
                    submenu="list-catalogo-todos-pb"
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/todos-filmes-catalogo-internacional"
              exact
              element={
                <ProtectedRoute roles={[1, 2]}>
                  <Catalogo
                    filter="internacionais_all"
                    submenu="list-catalogo-todos-int"
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/catalogo/cadastrar"
              exact
              element={
                <ProtectedRoute roles={[1, 2]}>
                  <CatalogoCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/catalogo/editar/:id"
              exact
              element={
                <ProtectedRoute roles={[1, 2]}>
                  <PeliculaEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/catalogo/download/:id"
              exact
              element={
                <ProtectedRoute roles={[1, 2]}>
                  <CatalogoDownload />
                </ProtectedRoute>
              }
            />
            <Route
              path="/catalogo/conteudo/revista"
              exact
              element={
                <ProtectedRoute roles={[1, 2]}>
                  <CatalogoRevista />
                </ProtectedRoute>
              }
            />
            <Route
              path="/projecao"
              exact
              element={
                <ProtectedRoute roles={[1, 2, 3]}>
                  <Projecao />
                </ProtectedRoute>
              }
            />
            <Route
              path="/premiere/todos"
              exact
              element={
                <ProtectedRoute roles={[1, 2]}>
                  <PremiereTodos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/premiere/selecao"
              exact
              element={
                <ProtectedRoute roles={[1, 2]}>
                  <PremiereSelecao />
                </ProtectedRoute>
              }
            />
            <Route
              path="/premiere/materiais"
              exact
              element={
                <ProtectedRoute roles={[1, 2]}>
                  <PremiereMateriais />
                </ProtectedRoute>
              }
            />
            <Route
              path="/premiere/final"
              exact
              element={
                <ProtectedRoute roles={[1, 2]}>
                  <PremiereFinal />
                </ProtectedRoute>
              }
            />
            <Route
              path="/premiere/imprensa"
              exact
              element={
                <ProtectedRoute roles={[1, 2]}>
                  <PremiereImprensa />
                </ProtectedRoute>
              }
            />
            <Route
              path="/internacional/todos"
              exact
              element={
                <ProtectedRoute roles={[1, 2]}>
                  <InternacionalTodos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/internacional/screeners"
              exact
              element={
                <ProtectedRoute roles={[1, 2]}>
                  <InternacionalScreeners />
                </ProtectedRoute>
              }
            />
            <Route
              path="/internacional/materiais"
              exact
              element={
                <ProtectedRoute roles={[1, 2]}>
                  <InternacionalMateriais />
                </ProtectedRoute>
              }
            />
            <Route
              path="/internacional/final"
              exact
              element={
                <ProtectedRoute roles={[1, 2]}>
                  <InternacionalFinal />
                </ProtectedRoute>
              }
            />
            <Route
              path="/internacional/imprensa"
              exact
              element={
                <ProtectedRoute roles={[1, 2]}>
                  <InternacionalImprensa />
                </ProtectedRoute>
              }
            />
            <Route
              path="/programacao/pelicula"
              exact
              element={
                <ProtectedRoute roles={[1, 2, 3]}>
                  <ProgramacaoPelicula />
                </ProtectedRoute>
              }
            />
            <Route
              path="/programacao/pelicula/editar/:id"
              exact
              element={
                <ProtectedRoute roles={[1, 2, 3]}>
                  <ProgramacaoPeliculaEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/programacao/programacoes"
              exact
              element={
                <ProtectedRoute roles={[1, 2, 3]}>
                  <ProgramacaoProgramacoes />
                </ProtectedRoute>
              }
            />
            <Route
              path="/programacao/projecao/exportar"
              exact
              element={
                <ProtectedRoute roles={[1, 2, 3]}>
                  <CatalogoIngresso />
                </ProtectedRoute>
              }
            />
            <Route
              path="/programacao/renda"
              exact
              element={
                <ProtectedRoute roles={[1, 2, 3]}>
                  <ProgramacaoRenda />
                </ProtectedRoute>
              }
            />
            <Route
              path="/usuarios"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route
              path="/usuarios/cadastrar"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <UserCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/usuarios/editar/:id"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <UserEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/mostras"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <Mostras />
                </ProtectedRoute>
              }
            />
            <Route
              path="/mostras/cadastrar"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <MostraCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/mostras/editar/:id"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <MostraEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/submostras"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <SubMostras />
                </ProtectedRoute>
              }
            />
            <Route
              path="/submostras/cadastrar"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <SubMostraCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/submostras/editar/:id"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <SubMostraEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/categorias"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <Categorias />
                </ProtectedRoute>
              }
            />
            <Route
              path="/categorias/cadastrar"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <CategoriaCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/categorias/editar/:id"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <CategoriaEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vinhetas"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <Vinhetas />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vinhetas/cadastrar"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <VinhetaCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vinhetas/editar/:id"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <VinhetaEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/idiomas"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <Languages />
                </ProtectedRoute>
              }
            />
            <Route
              path="/idiomas/cadastrar"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <LanguageCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/idiomas/editar/:id"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <LanguageEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cinemas"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <Cinemas />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cinemas/cadastrar"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <CinemaCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cinemas/editar/:id"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <CinemaEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/diretores"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <Directors />
                </ProtectedRoute>
              }
            />
            <Route
              path="/diretores/cadastrar"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <DirectorCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/diretores/editar/:id"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <DirectorEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/elenco"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <Elenco />
                </ProtectedRoute>
              }
            />
            <Route
              path="/elenco/cadastrar"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <ElencoCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/elenco/editar/:id"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <ElencoEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/roteiristas"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <Roteiristas />
                </ProtectedRoute>
              }
            />
            <Route
              path="/roteiristas/cadastrar"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <RoteiristaCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/roteiristas/editar/:id"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <RoteiristaEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/distribuidoras"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <Distribuidoras />
                </ProtectedRoute>
              }
            />
            <Route
              path="/distribuidoras/cadastrar"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <DistribuidoraCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/distribuidoras/editar/:id"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <DistribuidoraEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/produtoras"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <Produtoras />
                </ProtectedRoute>
              }
            />
            <Route
              path="/produtoras/cadastrar"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <ProdutoraCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/produtoras/editar/:id"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <ProdutoraEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/produtores"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <Produtores />
                </ProtectedRoute>
              }
            />
            <Route
              path="/produtores/cadastrar"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <ProdutorCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/produtores/editar/:id"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <ProdutorEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/fotografos"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <Fotografos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/fotografos/cadastrar"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <FotografoCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/fotografos/editar/:id"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <FotografoEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/editores"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <Editores />
                </ProtectedRoute>
              }
            />
            <Route
              path="/editores/cadastrar"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <EditorCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/editores/editar/:id"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <EditorEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/musicos"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <Musicos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/musicos/cadastrar"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <MusicoCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/musicos/editar/:id"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <MusicoEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/som"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <DesenhistaDeSom />
                </ProtectedRoute>
              }
            />
            <Route
              path="/som/cadastrar"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <DesenhistaDeSomCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/som/editar/:id"
              exact
              element={
                <ProtectedRoute roles={[1]}>
                  <DesenhistaDeSomEdit />
                </ProtectedRoute>
              }
            />
          </Routes>
        </UserProvider>
      </Router>
    </Suspense>
  );
}

export default MainRoutes;
